<template>
    <div>
        <div class="absolute z-20">
            <TinyNavigation :prev="{name: 'review.show', params: {id }}" class="pt-6 px-8" />
        </div>
        <template v-if="!review_not_found">
            <div class="px-6 pt-20">
                <div>
                    <Video v-if="review.video" :video-url="video_src" :thumbnail="video_thumbnail" :key="review.video.id" />
                </div>
            </div>
        </template>
        <template v-else>
            <p class="text-white px-12 pt-10">{{ t('review_not_found') }}</p>
        </template>
    </div>
</template>

<script>
import TinyNavigation from "@/components/TinyNavigation";
import Video from "@/components/Video";
import { getReview } from "@/helpers/api/ReviewHelper";
import messages from "@/helpers/storage/MessageHelper";

export default {
    components: { TinyNavigation, Video },
    props: ['id'],
    created(){
        getReview(this.id)
            .then((data) => {
                // console.log(data)
                this.review = data;            
            })
            .catch(err => {
                if (err.status === 404 || err.status === 403){
                    this.review_not_found = true;
                } else {
                    messages.error(this.t('messages.something_went_wrong', {error: err.message}));
                }
            });
    },
    methods: {
    },
    computed: {
        video_src: function (){
            if(!this.review || !this.review.video){
                return "";
            }
            return this.review.video.path;
        },
        video_thumbnail: function (){
            if(!this.review || !this.review.video){
                return "";
            }
            return this.review.video.thumbnail;
        },
    },
    data(){
        return {
            review: {},
            review_not_found: false,
            playing: false,
        }
    },
}
</script>

<style  scoped>
    #myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
</style>